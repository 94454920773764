<template>
  <div class="page-box">
    <a-spin :spinning="pageLoading" class="page-content">
      <div v-if="baseInfo&&!baseInfo.bindingFlag">
        <BaseSubTitle title="微信小程序" />
        <div style='padding: 0 24px'>
          <div class="title">
            授权及发布小程序
            <a-button type="link">查看教程</a-button>
          </div>
          <div class="sub-title">已注册微信小程序，立即授权发布</div>
          <div class="tips">
            <p>使用微信小程序管理员账号扫码进行授权，授权过程中请勾选所有权限确保小程序功能完整性，如未注册小程序，可以从公众号后台免微信认证创建小程序或直接前往“微信公众平台”注册<span style='color:red;font-weight:900;'>企业主体</span>的小程序账号</p>
            <a-button
              class="button"
              type="primary"
              @click="wxGetPreAuthUrl"
              :loading="btnLoading"
            >立即授权</a-button>
          </div>
          <a class="link" href="https://mp.weixin.qq.com" target="_blank" type="link">官方注册小程序</a>
        </div>
      </div>
      <div v-if="baseInfo&&baseInfo.bindingFlag" class="base-info-box">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1" tab="基本信息">
            <div class="base-info-table">
              <div class="base-info-table-tr">
                <div class="label">小程序名称</div>
                <div class="value">{{baseInfo.name}}</div>
                <div class="operation">
                  <a-button type="link" @click="goWxWeb">修改</a-button>
                </div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">小程序头像</div>
                <div class="value">
                  <img :src="baseInfo.logo" alt class="img" />
                </div>
                <div class="operation">
                  <a-button type="link" @click="goWxWeb">修改</a-button>
                </div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">小程序码及线下物料下载</div>
                <div class="value" v-viewer>
                  <!-- <img :src="baseInfo.qrcodeUrl" alt class="img" /> -->
                  <base-img :src='qrcodeurl' class="img" />
                </div>
                <div class="operation">
                  <a-button type="link" @click="goWxWeb">下载</a-button>
                </div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">介绍</div>
                <div class="value">{{baseInfo.intro}}</div>
                <div class="operation">
                  <a-button type="link" @click="goWxWeb">修改</a-button>
                </div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">微信认证</div>
                <div class="value">{{baseInfo.verifyType === 0?'已认证':'未认证'}}</div>
                <div class="operation"></div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">主体信息</div>
                <div class="value">{{baseInfo.principalName}}</div>
                <div class="operation"></div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">服务类目</div>
                <div class="value">
                  <p v-for="(item,index) in baseInfo.categories" :key="index">{{item}}</p>
                </div>
                <div class="operation">
                  <a-button type="link" @click="goWxWeb">修改</a-button>
                </div>
              </div>
              <div class="base-info-table-tr">
                <div class="label">隐私设置</div>
                <div class="value">{{baseInfo.hideStatus === 0?'允许被搜索':'不允许被搜索'}}</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="域名管理" v-if='baseInfo.verifyType === 0'>
            <div style='margin-bottom:24px;' v-if='domain&&domain.wxOpenMaDomainResult'>
              <BaseSubTitle title="服务器域名" style='margin:0'/>
              <div class='domain-item-box'>
                <div class='domain-item-name'>request合法域名</div>
                <div class='domain-url-box'>
                  <div v-for="(item,index) in domain.wxOpenMaDomainResult.requestdomainList" :key="index">{{item}}</div>
                  <div v-if="domain.wxOpenMaDomainResult.requestdomainList.length === 0">空</div>
                </div>
              </div>
              <div class='domain-item-box'>
                <div class='domain-item-name'>socket合法域名</div>
                <div class='domain-url-box'>
                  <div v-for="(item,index) in domain.wxOpenMaDomainResult.wsrequestdomainList" :key="index">{{item}}</div>
                  <div v-if="domain.wxOpenMaDomainResult.wsrequestdomainList.length === 0">空</div>
                </div>
              </div>
              <div class='domain-item-box'>
                <div class='domain-item-name'>uploadFile合法域名</div>
                <div class='domain-url-box'>
                  <div v-for="(item,index) in domain.wxOpenMaDomainResult.uploaddomainList" :key="index">{{item}}</div>
                  <div v-if="domain.wxOpenMaDomainResult.uploaddomainList.length === 0">空</div>
                </div>
              </div>
              <div class='domain-item-box'>
                <div class='domain-item-name'>downloadFile合法域名</div>
                <div class='domain-url-box'>
                  <div v-for="(item,index) in domain.wxOpenMaDomainResult.downloaddomainList" :key="index">{{item}}</div>
                  <div v-if="domain.wxOpenMaDomainResult.downloaddomainList.length === 0">空</div>
                </div>
              </div>
            </div>
            <div v-if='domain&&domain.wxOpenMaWebDomainResult'>
              <BaseSubTitle title="业务域名" style='margin:0'/>
              <div class='domain-item-box'>
                <div class='domain-item-name'>webview合法域名</div>
                <div class='domain-url-box'>
                  <div v-for="(item,index) in domain.wxOpenMaWebDomainResult.webviewdomainList" :key="index">{{item}}</div>
                  <div v-if="domain.wxOpenMaWebDomainResult.webviewdomainList.length === 0">空</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed } from '@vue/composition-api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { shop } from '@/api'
export default {
  name: 'PageWxApplet',
  setup (props, { root }) {
    const state = reactive({
      baseInfo: {},
      btnLoading: false,
      pageLoading: false,
      domain: null
    })
    async function wxGetPreAuthUrl () {
      state.btnLoading = true
      let { code, data, msg } = await shop.wxGetPreAuthUrl({
        shopId: getSession(SHOPID)
      })
      state.btnLoading = false
      if (code === '00000') {
        if (data) {
          window.location.href = data
        }
      } else {
        root.$message.error(msg)
      }
    }
    const qrcodeurl = computed(() => {
      return root.$store.state.head.shopInfo.qrcodeUrl
    })
    onMounted(async () => {
      if (getQueryString('auth_code')) {
        wxSendQueryAuth(getQueryString('auth_code'))
      } else {
        await wxGetAppletBaseData()
        if (state.baseInfo.bindingFlag && state.baseInfo.verifyType === 0) {
          wxGetDomainResult()
        }
      }
    })
    function getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return decodeURIComponent(r[2])
      return null
    }
    async function wxSendQueryAuth (authCode) {
      let { code, msg } = await shop.wxSendQueryAuth({
        shopId: getSession(SHOPID),
        authorizationCode: authCode
      })
      if (code === '00000') {
        await wxGetAppletBaseData()
        if (state.baseInfo.bindingFlag) {
          wxGetDomainResult()
        }
      } else {
        root.$message.error(msg)
      }
    }
    async function wxGetAppletBaseData () {
      state.pageLoading = true
      let { code, data, msg } = await shop.wxGetAppletBaseData({
        shopId: getSession(SHOPID)
      })
      state.pageLoading = false
      if (code === '00000') {
        if (data) {
          data.categories = data.categories.split(',')
          state.baseInfo = data
        } else {
          state.baseInfo = {}
        }
      } else {
        root.$message.error(msg)
      }
    }
    async function callback (key) {

    }
    function goWxWeb () {
      window.open('https://mp.weixin.qq.com')
    }
    async function wxGetDomainResult () {
      let { code, data, msg } = await shop.wxGetDomainResult({
        shopId: getSession(SHOPID)
      })
      if (code === '00000') {
        state.domain = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    return {
      qrcodeurl,
      ...toRefs(state),
      wxGetPreAuthUrl,
      wxSendQueryAuth,
      getQueryString,
      wxGetAppletBaseData,
      callback,
      goWxWeb,
      wxGetDomainResult
    }
  }
}
</script>
<style lang="less" scoped>
.page-content {
  min-height: calc(100vh - 186px);
  width: 100%;
  .title {
    font-weight: 900;
    font-size: 24px;
    color: #000;
    margin-bottom: 18px;
  }
  .sub-title {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 12px;
  }
  .tips {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 14px;
    margin-bottom: 12px;
    .button {
      margin-left: 64px;
      flex-shrink: 0;
    }
  }
  .link {
    color: #1890ff !important;
  }
}
.base-info-box {
  width: 100%;
  .base-info-table-tr {
    display: flex;
    align-items: center;
    min-height: 64px;
    padding: 12px;
    border-bottom: 1px solid #eee;
    .label {
      width: 200px;
      flex-shrink: 0;
      margin-right: 32px;
      color: #666;
    }
    .value {
      flex: 1;
      color: #000;
    }
    .operation {
      width: 400px;
      text-align: center;
    }
  }
}
.img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 30px;
}
.domain-title {
  font-size: 18px;
  margin:12px 0 32px;
}
.domain-item-box {
  display: flex;
  align-items: center;
  padding: 12px 32px;
  line-height: 24px;
  border-bottom:1px solid #eee;
  .domain-item-name {
    width: 300px;
    flex-shrink: 0;
  }
  .link {
    flex:1;
    text-align: center;
    flex-shrink: 0;
  }
  .domain-url-box {
    flex:1;
  }
}
.domain-item-box:last-child {
  border-bottom: none;
}
</style>
